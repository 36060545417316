<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="31"
    height="32"
    viewBox="0 0 31 32"
  >
    <defs>
      <path
        id="5sh4a"
        d="M1339.91 462.88c-.47 0-1 .05-1.57.15-1.58.27-3.45 1.98-4.77 4.34a15.17 15.17 0 0 0-.71 13.2c3.18 7.79 9.57 10.55 14.09 10.55 1.9 0 3.71-.45 5.25-1.3 3.3-1.8 5.82-4.9 6.77-8.3.57-2.06.46-3.92-.32-5.24-.81-1.38-1.65-1.57-2.36-1.57-.54 0-1.13.13-1.75.27-.72.16-1.47.33-2.27.33-.5 0-.98-.07-1.44-.2-3.53-1.09-5.71-2.6-6.39-7.11-.54-3.6-1.9-5.12-4.53-5.12zm7.03 28.9c-6.22 0-11.99-4.3-14.7-10.96-2.66-6.5-.37-11.8.74-13.78 1.43-2.56 3.44-4.35 5.24-4.67a9.9 9.9 0 0 1 1.69-.16c2.96 0 4.61 1.8 5.2 5.69.62 4.19 2.56 5.54 5.91 6.56.4.12.8.18 1.25.18.72 0 1.43-.16 2.11-.31a8.83 8.83 0 0 1 1.9-.29c1.24 0 2.18.6 2.94 1.9.87 1.49 1.01 3.53.39 5.76-1 3.56-3.64 6.81-7.09 8.7a11.6 11.6 0 0 1-5.58 1.38z"
      />
      <path
        id="5sh4b"
        d="M1339.06 465.06c-.15 0-.3.03-.44.08-.42.19-.7.64-.8 1.27-.1.7.03 1.51.37 2.3.57 1.27 1.62 2.2 2.5 2.2.16 0 .3-.04.44-.1.92-.39 1.11-2.01.43-3.55-.56-1.28-1.61-2.2-2.5-2.2zm1.63 6.52c-1.18 0-2.43-1.05-3.12-2.6-.4-.9-.54-1.85-.42-2.66.13-.88.56-1.51 1.2-1.79.22-.1.46-.14.71-.14 1.18 0 2.43 1.05 3.11 2.6.86 1.94.51 3.9-.78 4.45-.22.1-.46.14-.7.14z"
      />
      <path
        id="5sh4c"
        d="M1335.28 472.42c-.88 0-1.6.7-1.6 1.58 0 .87.72 1.57 1.6 1.57.89 0 1.6-.7 1.6-1.57a1.6 1.6 0 0 0-1.6-1.58zm0 3.83c-1.26 0-2.28-1-2.28-2.25a2.27 2.27 0 0 1 2.28-2.25 2.27 2.27 0 0 1 2.27 2.25 2.27 2.27 0 0 1-2.27 2.25z"
      />
      <path
        id="5sh4d"
        d="M1337.94 479.82c-.88 0-1.6.7-1.6 1.58 0 .87.72 1.57 1.6 1.57.89 0 1.6-.7 1.6-1.57a1.6 1.6 0 0 0-1.6-1.58zm0 3.83c-1.26 0-2.28-1-2.28-2.25a2.27 2.27 0 0 1 2.28-2.25c1.25 0 2.27 1 2.27 2.25a2.27 2.27 0 0 1-2.27 2.25z"
      />
      <path
        id="5sh4e"
        d="M1343.57 484.82c-.88 0-1.6.7-1.6 1.58 0 .87.72 1.57 1.6 1.57.89 0 1.6-.7 1.6-1.57a1.6 1.6 0 0 0-1.6-1.58zm0 3.83c-1.26 0-2.28-1-2.28-2.25a2.27 2.27 0 0 1 2.28-2.25c1.25 0 2.27 1 2.27 2.25a2.27 2.27 0 0 1-2.27 2.25z"
      />
      <path
        id="5sh4f"
        d="M1351.3 484.4c-.88 0-1.6.7-1.6 1.58 0 .87.72 1.57 1.6 1.57.89 0 1.6-.7 1.6-1.57a1.6 1.6 0 0 0-1.6-1.58zm0 3.83c-1.26 0-2.28-1-2.28-2.25a2.27 2.27 0 0 1 2.28-2.25c1.25 0 2.27 1 2.27 2.25a2.27 2.27 0 0 1-2.27 2.25z"
      />
      <path
        id="5sh4g"
        d="M1355.18 477.9c-.88 0-1.6.71-1.6 1.59 0 .87.72 1.57 1.6 1.57.89 0 1.6-.7 1.6-1.57a1.6 1.6 0 0 0-1.6-1.58zm0 3.84c-1.26 0-2.28-1-2.28-2.25a2.27 2.27 0 0 1 2.28-2.25c1.25 0 2.27 1 2.27 2.25a2.27 2.27 0 0 1-2.27 2.25z"
      />
    </defs>
    <g>
      <g transform="translate(-1330 -461)">
        <g>
          <use fill="#363636" xlink:href="#5sh4a" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".7"
            xlink:href="#5sh4a"
          />
        </g>
        <g>
          <use fill="#363636" xlink:href="#5sh4b" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".7"
            xlink:href="#5sh4b"
          />
        </g>
        <g>
          <use fill="#363636" xlink:href="#5sh4c" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".7"
            xlink:href="#5sh4c"
          />
        </g>
        <g>
          <use fill="#363636" xlink:href="#5sh4d" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".7"
            xlink:href="#5sh4d"
          />
        </g>
        <g>
          <use fill="#363636" xlink:href="#5sh4e" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".7"
            xlink:href="#5sh4e"
          />
        </g>
        <g>
          <use fill="#363636" xlink:href="#5sh4f" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".7"
            xlink:href="#5sh4f"
          />
        </g>
        <g>
          <use fill="#363636" xlink:href="#5sh4g" />
          <use
            fill="#fff"
            fill-opacity="0"
            stroke="#363636"
            stroke-miterlimit="50"
            stroke-width=".7"
            xlink:href="#5sh4g"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ThePaintPaletteIcon',
};
</script>
